import {showModal} from "./modal";

window.onload = () => {
    const form = document.getElementById('registration-form');

    form.addEventListener('submit', (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        fetch('/.netlify/functions/handle-lead-registration', {
            body: JSON.stringify(Object.fromEntries(formData)),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.ok) {
                showModal('registration-modal');
                form.reset();
            } else {
                throw new Error('Ocurrió un error en el servidor');
            }
        }).catch(error => {
            alert(error);
        });
    });
}

document.addEventListener("DOMContentLoaded", function() {

    document.getElementById('privacy-modal-trigger').addEventListener('click', (e) => showModal('privacy-modal'));
});

window.scrollToForm = () => {
    document.getElementById('form').scrollIntoView({ behavior: "smooth" });
}
