document.addEventListener("DOMContentLoaded", function (event) {
    let modals = document.querySelectorAll('.modal');

    modals.forEach(modal => {
        let closeButtons = modal.querySelectorAll(".close");

        closeButtons.forEach(closeButton => {
            closeButton.addEventListener('click', () => closeModal(modal));
        });
    });

    window.addEventListener('click', outsideClick);
});


function showModal(id) {
    let modal = document.getElementById(id);
    modal.classList.add('active');
    document.body.classList.add('modal-active');
}

function closeModal(modal) {
    modal.classList.remove('active');
    document.body.classList.remove('modal-active');
}

function outsideClick(e) {
    let activeModal = document.querySelector('.modal.active');

    if(activeModal){
        if (activeModal.contains(e.target) && !activeModal.querySelector('.modal-content').contains(e.target)) {
            closeModal(activeModal);
        }
    }
  
}

export {showModal};